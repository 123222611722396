import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"
import PageTitle from "../components/page-title"

class Subscrever extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Líbero Aberto - Subscrever"
          description="Assine para receber os conteúdos do nosso site e alguns exclusivos no seu e-mail."
        />
        <PageTitle>Líbero Aberto</PageTitle>
        <article>
          <p style={{ marginTop: "20px" }}>
            A <strong>Líbero Aberto</strong> nasceu no início de maio de 2020 e
            tornou-se no segundo de três produtos associados à marca{" "}
            <i>Vasco Samouco</i>. É entregue aos subscritores todas as{" "}
            <strong>segundas-feiras</strong> e serve para eu partilhar os
            conteúdos mais interessantes e mais enriquecedores que consultei nos
            dias anteriores à sua publicação e que, acredito, podem ser valiosos
            para quem tiver acesso a eles.
          </p>
          <p>
            Na Newsletter disponibilizarei trabalhos, estudos, entrevistas,
            reportagens ou conversas relacionadas com{" "}
            <strong>
              Gestão, Liderança, Formação, Educação e Alto-Rendimento
            </strong>
            , normalmente enquadradas num contexto desportivo, mas também
            relacionadas com Ciências Sociais e Ciências Humanas, nomeadamente
            Psicologia, Neurociências, Sociologia, Medicina, Neurociências ou
            Filosofia, e Desenvolvimento Pessoal, por serem áreas que, estou
            convicto, têm muito para acrescentar ao Desporto, ao Futebol e ao
            Mundo Empresarial.
          </p>
          <p>
            Porque a Informação é cada vez mais dinâmica, pública e universal, e
            nos chega cada vez mais rápido, tenho o cuidado de selecionar muito
            bem o que partilho, para serem conteúdos verdadeiramente
            diferenciadores, com pouco espaço mediático e que passaram quase
            despercebidos sem a atenção devida.
          </p>
          <p>
            Pode ver alguns exemplos da <strong>Líbero Aberto</strong>{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mailchi.mp/1a763bdcf5fe/lbero-aberto-4"
            >
              aqui
            </a>{" "}
            e{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mailchi.mp/d90c2c647cd6/lbero-aberto-8"
            >
              aqui
            </a>
            .
          </p>
        </article>
        <Subscribe />
      </Layout>
    )
  }
}

export default Subscrever

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
